import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Form from './Form'
import { getValidationErrors } from '@/utility'
import LOCATION from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import AppModal from '@/shared/components/AppModal'
import { Breadcrumb } from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'

function Create(props){
    const auth = useAuth()
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [savingPromotion, setSavingPromotion] = useState(false)
    const [user, setPromotion] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [confirmText, setConfirmText] = useState(null)

    const [serverErrors, setServerErrors] = useState([]);

    const { id } = useParams()

    const createPromotion = (values) => {
        setServerErrors([])
        setSavingPromotion(true)
        auth.postRequest(`/subscription-product-promotions`, values)
            .then(response => {
                setPromotion(response?.data.data);
                setSavingPromotion(false)
                setModalTitle(t('pages.users.notifications.admin_created.title'))
                setConfirmText(t('common.dialogs.actions.okay'))
                setShowModal(true)
            })
            .catch(error => {
                setSavingPromotion(false)
                setServerErrors(getValidationErrors(error))
            })
    }

    const handleConfirm = () => {
        setShowModal(false)
        props.history.push(`${LOCATION.SUBSCRIPTION_MANAGEMENT.LIST.path}`)
    }

    return (
        <>
            <AppModal
                show={showModal}
                title={modalTitle}
                confirmText={confirmText}
                handleConfirm={handleConfirm}
            />
            <Helmet>
                <title>Create Promotion - {t('common.app')}</title>
            </Helmet>

            <div className="p-4">
                <Form
                    admin={true}
                    updatePromotion={createPromotion}
                    savingPromotion={savingPromotion}
                    serverErrors={serverErrors}
                    submitLabel="common.buttons.submit"
                    productId={id}
                />
            </div>
        </>
    )
}

export default Create;