import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@/shared/components/LoadingButton'
import LoadingCover from '@/shared/components/LoadingCover'

function PromotionForm ({ promotion, updatePromotion, savingPromotion, serverErrors, submitLabel, admin, productId }) {
    const { t } = useTranslation()

    const schema = yup.object().shape({
        code: yup.string()
            .required("Promotion code is required"),
        price: yup.number()
            .required("Price is required"),
        interval: yup.string()
            .required("Interval is required"),
        frequency: yup.number()
            .required("Frequency is required"),
    })

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: (_ => {
            return {
                code: promotion?.code,
                price: promotion?.price,
                interval: promotion?.interval,
                frequency: promotion?.frequency,
                subscription_product_id: productId
            }
        })(),
        resolver: yupResolver(schema),
    })

    return (
        <>
            <Form className="promotion-form" noValidate onSubmit={handleSubmit(updatePromotion)}>
                <Form.Group className="my-4">
                    <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                        Code
                        <span className="text-danger ms-2 small">*</span>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('common.form.placeholder_enter', { attribute: 'Code' })}
                        {...register('code')}
                        isInvalid={!!errors.code}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors?.code?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                        Price
                        <span className="text-danger ms-2 small">*</span>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder={t('common.form.placeholder_enter', { attribute: 'Price' })}
                        {...register('price')}
                        isInvalid={!!errors.price}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors?.price?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                        Interval
                        <span className="text-danger ms-2 small">*</span>
                    </Form.Label>
                    <div className="form-control">
                        <select
                            className="form-select"
                            {...register('interval')}
                            defaultValue={promotion?.interval}
                        >
                            <option value="month">Months</option>
                            <option value="year">Years</option>
                        </select>
                    </div>
                    <Form.Control.Feedback type="invalid">
                        {errors?.interval?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-4">
                    <Form.Label className="fw-bold text-uppercase d-flex align-items-center heading-text">
                        Frequency
                        <span className="text-danger ms-2 small">*</span>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder={t('common.form.placeholder_enter', { attribute: 'Frequency' })}
                        {...register('frequency')}
                        isInvalid={!!errors.frequency}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors?.frequency?.message}
                    </Form.Control.Feedback>
                </Form.Group>


                {
                    (serverErrors.length !== 0) &&
                    <div className="form-group mb-4">
                        {
                            serverErrors.map((error, index) =>
                                <p className="text-danger font-weight-bold"
                                   key={index}>{t(error)}</p>)
                        }
                    </div>
                }
                <Form.Group className="d-grid gap-2 mt-5">
                    <LoadingButton
                        className="auth-btn text-white px-5 heading-text text-uppercase"
                        loading={savingPromotion}
                        variant="primary"
                        titleTranslationKey={submitLabel}
                        type="submit"
                    />
                </Form.Group>
            </Form>
        </>
    )

}

export default PromotionForm