import {Card, Col, Form, Nav, Row, Tab, Tabs} from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import StripeSetupForm from './StripeSetupForm'
import TravelMaxFeatures from '../../Banners/components/TravelMaxFeatures'
import React, {useState} from 'react'
import { useAuth } from '@/services/Auth'
import LoadingButton from '@/shared/components/LoadingButton'
import { useTranslation } from 'react-i18next'
import PaymentMethodInformation from '../../Profile/components/PaymentMethodInformation'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import TrialBenefits from '../../Banners/TrialBenefits'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";

function SubscriptionForm ({
  processSubmit,
  savePaymentMethod,
  stripePromise,
  stripeSetupFormRef,
  errors,
  loading,
  subscribe,
  setSubscriptionDuration,
  subscriptionDuration,
  trial,
  serverError,
  checkoutWithPaypal,
  processingPaypal,
  register,
  promoValid,
}) {

  const auth = useAuth()
  const { t } = useTranslation()

  const TravelMaxPriceYearly = 30
  const TravelMaxPrice3months = 8

  return (
    <div className="container">
      <Row className="my-4 px-2 px-lg-4">
        {

          trial &&
          <Col className="col-md-6 col-12 border-0 d-flex justify-content-center">
            <div className={`trial-container p-0 mt-3 px-md-5 `}>
              <TrialBenefits/>
            </div>
          </Col>
        }
        <Col className="col-md-6 col-12  p-2 p-lg-4">
          {
            !trial &&
            <>
              <h3 className="heading-text my-3">{t('pages.payments.subscribe_to_travel_max')}</h3>
              <h4 className="my-4 heading-text text-primary head w-75">
                {t('common.travel_max_feature.price_title', {
                  price: TravelMaxPriceYearly.toFixed(2),
                  currency: auth.user.currency.symbol
                })}
              </h4>
              <p className="my-4 lead">{t('common.travel_max_feature.gain_access_statement')}</p>

            </>
          }


          <Card>
            <Card.Header>
              <h5 className="mb-0">Select Payment Method</h5>
            </Card.Header>
            <Card.Body>
              <Tab.Container id="payment-methods-tabs" defaultActiveKey="card">
                <Row>
                  <Col className="col-12">
                    <Form.Group className="mb-3">
                        <Form.Label className="heading-text">Promo Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Promo Code"
                            {...register('promo_code')}
                        />
                    </Form.Group>
                  </Col>
                  <Col className="col-12">
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="card">
                          <FontAwesomeIcon icon={faCreditCard}/>
                          <span className="ms-2">{t('pages.payments.credit_card')}</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="w-50">
                        <Nav.Link eventKey="paypal">
                          <img src="/assets/images/paypal.svg" className="paypal-button-tab-title" alt="paypal-image"/>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col className="col-12">
                    <Tab.Content>
                      <Tab.Pane eventKey="card">
                        <div className={`h-100 mt-0`}>
                          {
                            auth.user.payment_method ?
                              <>
                                <h5 className="heading-text">{t('pages.profile.payment_details')}</h5>
                                <PaymentMethodInformation user={auth.user}/>
                                {serverError &&
                                  <p className="text-danger my-3">{t(`pages.payments.${serverError}`)}</p>}
                                <div className="d-grid gap-2 mt-2">
                                  <LoadingButton
                                    className="auth-btn text-uppercase fw-bold text-primary heading-text mt-3"
                                    loading={loading}
                                    variant="dark"
                                    size="lg"
                                    onSubmit={subscribe}
                                    titleTranslationKey={trial ? t('pages.payments.buttons.subscribe_for_a_trial', {
                                        price: subscriptionDuration === 'year' ? TravelMaxPriceYearly.toFixed(2) : TravelMaxPrice3months.toFixed(2),
                                        currency: auth.user.currency.symbol
                                      })
                                      :
                                      t('pages.payments.buttons.pay_and_subscribe', {
                                        price: subscriptionDuration === 'year' ? TravelMaxPriceYearly.toFixed(2) : TravelMaxPrice3months.toFixed(2),
                                        currency: auth.user.currency.symbol
                                      })}
                                    type="button"
                                  />
                                </div>
                              </>
                              :
                              <Card className="mt-0 border-0">
                                <Form noValidate onSubmit={processSubmit(savePaymentMethod)}>
                                  <Card.Body className="p-0">
                                    <div className={`${trial ? 'my-0' : 'my-3'}`}>
                                      <Elements stripe={stripePromise}>
                                        <StripeSetupForm ref={stripeSetupFormRef} errors={errors}/>
                                      </Elements>
                                      {serverError &&
                                        <p className="text-danger my-3">{t(`pages.payments.${serverError}`)}</p>}
                                    </div>
                                    <div className="d-grid mt-4">
                                      <LoadingButton
                                        className="auth-btn text-uppercase fw-bold text-primary heading-text"
                                        loading={loading}
                                        variant="dark"
                                        size="lg"
                                        titleTranslationKey={trial ? t('pages.payments.buttons.subscribe_for_a_trial', {
                                            price: subscriptionDuration === 'year' ? TravelMaxPriceYearly.toFixed(2) : TravelMaxPrice3months.toFixed(2),
                                            currency: auth.user.currency.symbol
                                          })
                                          :
                                          t('pages.payments.buttons.pay_and_subscribe', {
                                            price: subscriptionDuration === 'year' ? TravelMaxPriceYearly.toFixed(2) : TravelMaxPrice3months.toFixed(2),
                                            currency: auth.user.currency.symbol
                                          })}
                                        type="submit"
                                        disabled={promoValid === false}
                                      />
                                    </div>
                                  </Card.Body>
                                </Form>
                              </Card>
                          }
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="paypal" className="pt-4">
                        {serverError && <p className="text-danger my-3">{t(`pages.payments.${serverError}`)}</p>}

                        <span className="py-4">{t('pages.payments.paypal_statement')}</span>
                        <div className="d-grid pt-4">
                          <LoadingButton
                            className="auth-btn text-uppercase fw-bold text-primary heading-text"
                            variant="dark"
                            loading={processingPaypal}
                            titleTranslationKey={trial ? t('pages.payments.buttons.subscribe_for_a_trial', {
                                price: subscriptionDuration === 'year' ? TravelMaxPriceYearly.toFixed(2) : TravelMaxPrice3months.toFixed(2),
                                currency: auth.user.currency.symbol
                              })
                              :
                              t('pages.payments.buttons.pay_and_subscribe', {
                                price: subscriptionDuration === 'year' ? TravelMaxPriceYearly.toFixed(2) : TravelMaxPrice3months.toFixed(2),
                                currency: auth.user.currency.symbol
                              })}
                            disabled={promoValid === false}
                            onSubmit={() => promoValid !== false && checkoutWithPaypal()}
                          />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Card.Body>
            <Card.Footer>

              <div className="d-grid">
                <Link
                  to={LOCATION.ROOT.path}
                  className="auth-btn text-uppercase fw-bold text-dark heading-text mt-3 btn btn-primary btn-lg">
                  {t('common.travel_max_feature.buttons.maybe_later')}
                </Link>
              </div>
            </Card.Footer>
          </Card>
        </Col>
        {
          !trial &&
          <Col className="col-md-6 col-12 p-2 p-lg-4 ">
            <TravelMaxFeatures/>
          </Col>
        }
      </Row>
    </div>
  )
}

export default SubscriptionForm